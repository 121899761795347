import { pipe } from "fp-ts/function"
import _ from "lodash"

export const removeQueryParamFromUrl =
    (param: string) =>
    (url: URL): URL => {
        const paramsObject = Object.fromEntries(url.searchParams.entries())
        const newParamsObject = _.omit(paramsObject, param)
        const newSearch = new URLSearchParams(newParamsObject).toString()

        const newUrl = new URL(url.toString())
        newUrl.search = newSearch
        return newUrl
    }

export const removeQueryParamFromStr =
    (param: string) =>
    (urlStr: string): string =>
        pipe(new URL(urlStr), removeQueryParamFromUrl(param)).toString()

export const removeQueryParamFromLocation =
    (param: string) =>
    (location: Location): string =>
        pipe(new URL(location.href), removeQueryParamFromUrl(param)).toString()

export const base64WithUrlEncode = (str: string) => safeUrlEncode(btoa(str))
export const base64WithUrlDecode = (encodedStr: string) =>
    safeUrlDecode(atob(encodedStr))

export const safeUrlEncode = (str: string) => encodeURIComponent(str)
export const safeUrlDecode = (encodedStr: string) =>
    decodeURIComponent(encodedStr)

export const removeTrailSlash = (url: string) => url.replace(/\/+$/, "")
