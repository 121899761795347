import { pluginFetch, pluginToken } from "@zodios/plugins"
import { flow } from "fp-ts/function"
import { tryCatchK } from "fp-ts/TaskEither"

import { LocalStorage } from "../local-storage"
import { AccountType, ApiError, OidcUser, Pagination } from "./api-models"
import { Url, UserProfileId } from "./branded-types"

import {
    InfiniteData,
    QueryClient,
    UseInfiniteQueryResult,
} from "@tanstack/react-query"
import { ZodiosPlugin, zodValidationPlugin } from "@zodios/core"
import { AUTHORITY, CLIENT_ID } from "envs"
import { identity, pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import {
    User,
    UserManager,
    UserManagerSettings,
    WebStorageStateStore,
} from "oidc-client-ts"
import { useEffect, useRef, useState } from "react"

export const oidcConfig: UserManagerSettings = {
    authority: AUTHORITY,
    response_type: "code",

    client_id: CLIENT_ID,
    scope: "openid",

    redirect_uri: window.location.origin + "/callback.html",
    post_logout_redirect_uri: window.location.origin + "/callback.html",

    userStore: new WebStorageStateStore({
        store: window.localStorage,
    }),

    automaticSilentRenew: true,
    // FIXME silent login causes
    // 'Token renewal failed: ErrorTimeout: IFrame timed out without a response'
    // workaround which needs longer investigation
    silentRequestTimeoutInSeconds: 4,
}

const authClient = new UserManager(oidcConfig)

export const corsFetchHandler = pluginFetch({
    mode: "cors",
    keepalive: true,
    credentials: "include",
})

export const renewToken = async () => {
    try {
        const newAccessToken = await authClient.signinSilent()

        if (newAccessToken) {
            LocalStorage.setAccessToken(newAccessToken.access_token)
            return newAccessToken.access_token
        }
    } catch (error) {
        location.href = "/intro"
        console.error("Token renewal failed:", error)
    }

    return undefined
}

export const tokenHandlerForCreate = pluginToken({
    getToken: async () => LocalStorage.getAccessToken(),
    renewToken: async () => await renewToken(),
})

// remove token whenever get is not possible since token expired
export const tokenHandlerForGet = pluginToken({
    getToken: async () => LocalStorage.getAccessToken(),
    renewToken: async () => await renewToken(),
})

export const endpointWithTaskEither = <
    result,
    params extends readonly unknown[],
>(
    f: (...p: params) => Promise<result>,
) =>
    flow(
        f,
        tryCatchK(
            fp => fp,
            e => e as ApiError,
        ),
    )

// TODO improve and extend fun
export const mapToMatchFilterDc = (
    key: string,
    data: unknown[],
    extension?: string,
) =>
    extension === undefined
        ? `$match: {${key}: in ${JSON.stringify(data)}}`
        : `$match: {${key}: in ${JSON.stringify(data)}, ${extension}}`

// TODO improve and extend fun
export const mapToNotMatchFilterDc = (
    key: string,
    data: unknown[],
    extension?: string,
) =>
    extension === undefined
        ? `$match: {${key}: !in ${JSON.stringify(data)}}`
        : `$match: {${key}: !in ${JSON.stringify(data)}, ${extension}}`

// TODO improve and extend fun
// {$sort: {engagementScore: desc, profileName: asc}}
export const mapToSortFilterDc = (description: string) =>
    `$sort: {${description}}`

export const bgImageUrl = (url: Url) => `
    url(${url}?height=${window.innerHeight / 4})
    no-repeat center/cover`

type WithDate = {
    id: string
    createdAt: string
}

type MergeSortedInfiniteDataModel<
    TFirst extends WithDate,
    TSecond extends WithDate,
> = {
    firstItems: TFirst[]
    secondItems: TSecond[]
    firstHasNextPage: boolean
    secondHasNextPage: boolean
    desiredAmount: number
}
//TODO: support paginated data
//? Idea: move to query instead of merging here
export const mergeSortedInfiniteData = <
    TFirst extends WithDate,
    TSecond extends WithDate,
>(
    model: MergeSortedInfiniteDataModel<TFirst, TSecond>,
) => {
    const mergedData: (TFirst | TSecond)[] = []
    let firstIndex = 0
    let secondIndex = 0

    while (
        (firstIndex < model.firstItems.length ||
            secondIndex < model.secondItems.length) &&
        mergedData.length < model.desiredAmount
    ) {
        const currentFirstItem = model.firstItems[firstIndex]
        const currentSecondItem = model.secondItems[secondIndex]

        if (currentFirstItem && currentSecondItem) {
            if (
                new Date(currentFirstItem.createdAt) >
                new Date(currentSecondItem.createdAt)
            ) {
                mergedData.push(currentFirstItem)
                firstIndex++
            } else {
                mergedData.push(currentSecondItem)
                secondIndex++
            }
        } else if (currentFirstItem) {
            mergedData.push(currentFirstItem)
            firstIndex++
        } else if (currentSecondItem) {
            mergedData.push(currentSecondItem)
            secondIndex++
        } else {
            break
        }
    }

    return mergedData
}

type UseInfiniteQueries<F extends WithDate, S extends WithDate> = {
    firstQuery: UseInfiniteQueryResult<
        InfiniteData<
            {
                data: F[]
                paging: Pagination
            },
            unknown
        >,
        Error
    >
    secondQuery: UseInfiniteQueryResult<
        InfiniteData<
            {
                data: S[]
                paging: Pagination
            },
            unknown
        >,
        Error
    >
    initialItemsAmount: number
    incrementLoadAmount: number
}

export const useMergedInfiniteQueries = <
    TFirst extends WithDate,
    TSecond extends WithDate,
>(
    model: UseInfiniteQueries<TFirst, TSecond>,
) => {
    const {
        data: firstQueryData,
        fetchNextPage: fetchNextPageFirstQuery,
        hasNextPage: hasNextPageFirstQuery,
        isLoading: isLoadingFirstQuery,
        isFetchingNextPage: isFetchingNextPageFirstQuery,
    } = model.firstQuery

    const {
        data: secondQueryData,
        fetchNextPage: fetchNextPageSecondQuery,
        hasNextPage: hasNextPageSecondQuery,
        isLoading: isLoadingSecondQuery,
        isFetchingNextPage: isFetchingNextPageSecondQuery,
    } = model.secondQuery

    const [mergedData, setMergedData] = useState<(TFirst | TSecond)[]>([])
    const [firstQueryPointer, setFirstQueryPointer] = useState<number>(0)
    const [secondQueryPointer, setSecondQueryPointer] = useState<number>(0)
    const [desiredAmount, setDesiredAmount] = useState<number>(
        model.initialItemsAmount,
    )
    let totalItems = mergedData.length
    const seenIds = useRef<Set<string>>(new Set())

    useEffect(() => {
        const mergeData = () => {
            if (
                isLoadingFirstQuery ||
                isLoadingSecondQuery ||
                isFetchingNextPageFirstQuery ||
                isFetchingNextPageSecondQuery
            )
                return
            const firstQueryItems =
                firstQueryData?.pages.flatMap(page => page.data) || []
            const secondQueryItems =
                secondQueryData?.pages.flatMap(page => page.data) || []

            const newData: (TFirst | TSecond)[] = []
            let firstQueryCurrentIndex = firstQueryPointer
            let secondQueryCurrentIndex = secondQueryPointer

            const addItem = (item: TFirst | TSecond) => {
                if (!seenIds.current.has(item.id)) {
                    newData.push(item)
                    seenIds.current.add(item.id)
                }
            }

            while (
                firstQueryCurrentIndex < firstQueryItems.length &&
                secondQueryCurrentIndex < secondQueryItems.length &&
                totalItems < desiredAmount
            ) {
                if (
                    new Date(
                        firstQueryItems[firstQueryCurrentIndex].createdAt,
                    ) >
                    new Date(
                        secondQueryItems[secondQueryCurrentIndex].createdAt,
                    )
                ) {
                    addItem(firstQueryItems[firstQueryCurrentIndex])
                    firstQueryCurrentIndex++
                } else {
                    addItem(secondQueryItems[secondQueryCurrentIndex])
                    secondQueryCurrentIndex++
                }
                totalItems++
            }

            while (
                firstQueryCurrentIndex < firstQueryItems.length &&
                totalItems < desiredAmount
            ) {
                addItem(firstQueryItems[firstQueryCurrentIndex])
                firstQueryCurrentIndex++
                totalItems++
            }

            while (
                secondQueryCurrentIndex < secondQueryItems.length &&
                totalItems < desiredAmount
            ) {
                addItem(secondQueryItems[secondQueryCurrentIndex])
                secondQueryCurrentIndex++
                totalItems++
            }

            setFirstQueryPointer(firstQueryCurrentIndex)
            setSecondQueryPointer(secondQueryCurrentIndex)

            if (totalItems < desiredAmount) {
                if (
                    firstQueryCurrentIndex >= firstQueryItems.length &&
                    hasNextPageFirstQuery
                ) {
                    fetchNextPageFirstQuery()
                }
                if (
                    secondQueryCurrentIndex >= secondQueryItems.length &&
                    hasNextPageSecondQuery
                ) {
                    fetchNextPageSecondQuery()
                }
            }
            //TODO: sorting should not be necessary, could run into potential performance issues
            //TODO: (investigate implementation in more detail)
            setMergedData(prevData =>
                [...prevData, ...newData].sort(
                    (a, b) =>
                        new Date(b.createdAt).valueOf() -
                        new Date(a.createdAt).valueOf(),
                ),
            )
        }

        mergeData()
    }, [
        firstQueryData,
        secondQueryData,
        firstQueryPointer,
        secondQueryPointer,
        desiredAmount,
        hasNextPageFirstQuery,
        hasNextPageSecondQuery,
        isFetchingNextPageFirstQuery,
        isFetchingNextPageSecondQuery,
        fetchNextPageFirstQuery,
        fetchNextPageSecondQuery,
        totalItems,
        isLoadingFirstQuery,
        isLoadingSecondQuery,
    ])

    const fetchNext = () => {
        setDesiredAmount(prevCount => prevCount + model.incrementLoadAmount)
    }

    return {
        data: mergedData,
        fetchNext,
    }
}

type WithAuthorizedAccessModel = {
    onUnauthorized?: () => void
    onAuthorized?: () => unknown
    accountType?: AccountType
}

export const withAuthorizedAccess = (params: WithAuthorizedAccessModel) =>
    pipe(params.accountType, getIsAuthorizedAccount, authorized =>
        authorized ? params.onAuthorized : params.onUnauthorized,
    )

export const getIsAuthorizedAccount = (accountType?: AccountType) =>
    pipe(
        accountType,
        O.fromNullable,
        O.fold(() => "Anonymous" as const, identity),
        type => type === "User",
    )

export const mapToOidcUser = (user: User): OidcUser => ({
    idToken: user.id_token,
    accessToken: user.access_token,
    refreshToken: user.refresh_token,
    expiresAt: user.expires_at,
    accountType: AccountType.parse(user.profile.idt),
})

export const isPromiseFulfilled = <T>(
    promiseResult: PromiseSettledResult<T>,
): promiseResult is PromiseFulfilledResult<T> =>
    promiseResult.status === "fulfilled"

export const isPromiseRejected = <T>(
    promiseResult: PromiseSettledResult<T>,
): promiseResult is PromiseRejectedResult => promiseResult.status === "rejected"

const zodValidationPluginInstance = zodValidationPlugin({
    sendDefaults: false,
    transform: true,
    validate: true,
})

export const validationErrorHandler: ZodiosPlugin = {
    name: zodValidationPluginInstance.name,
    response: async (api, config, response) => {
        const matchedEndpoint = api.find(
            endpoint => config.url === endpoint.path,
        )

        if (!matchedEndpoint) {
            console.error(`No matching endpoint found for URL: ${config.url}`)
            return response
        }
        const result = matchedEndpoint.response.safeParse(response.data)
        if (result.error) {
            console.info(response.data)
            console.error(
                `Zod validation error for ${matchedEndpoint.path}`,
                result.error,
            )
        }
        return response
    },
    request: zodValidationPluginInstance.request,
    error: zodValidationPluginInstance.error,
}

export const getQueryClientMissingIds = <T>(
    client: QueryClient,
    ids: T[],
    cacheKey: (id: T) => any,
): T[] => ids.filter(id => !client.getQueryData(cacheKey(id)))

export const mergeWithQueryCache = <T, TItem extends { id: string }>(
    client: QueryClient,
    ids: T[],
    cacheKey: (id: T) => (string | undefined)[],
    fetchedData: TItem[],
) =>
    ids
        .map(id => {
            const cachedData = client.getQueryData<TItem>(cacheKey(id))
            return cachedData || fetchedData.find(item => item.id === id)
        })
        .filter(Boolean) as TItem[]

type PageWithUserProfileId = {
    data: { creatorId: UserProfileId }[]
}

export const getMergedProfileIdPages = <
    TFirst extends PageWithUserProfileId[],
    TSecond extends PageWithUserProfileId[],
>(
    firstPages: TFirst,
    secondPages: TSecond,
) => {
    const maxPages = Math.max(firstPages.length, secondPages.length)
    const mergedPages: UserProfileId[][] = []

    for (let i = 0; i < maxPages; i++) {
        const firstPage = firstPages.at(i)
        if (firstPage) {
            mergedPages.push(firstPage.data.map(item => item.creatorId))
        }

        const secondPage = secondPages.at(i)
        if (secondPage) {
            mergedPages.push(secondPage.data.map(item => item.creatorId))
        }
    }

    return mergedPages
}
