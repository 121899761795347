import { parseBoolean } from "utils/type"

export const DEBUG = import.meta.env.VITE_DEBUG
export const APP_VERSION = __APP_VERSION__

export const API_URL = import.meta.env.VITE_API_URL
export const SHARE_LINK_API = import.meta.env.VITE_SHARE_LINK_API

export const PUSH_VAPID = import.meta.env.VITE_PUSH_VAPID

export const AUTHORITY = import.meta.env.VITE_AUTHORITY
export const CLIENT_ID = import.meta.env.VITE_CLIENT_ID

export const EDIT_POSTIT_MONETIZE_ENABLED = parseBoolean(
    import.meta.env.VITE_EDIT_POSTIT_MONETIZE_ENABLED,
)
export const EDIT_VIDEO_MONETIZE_ENABLED = parseBoolean(
    import.meta.env.VITE_EDIT_VIDEO_MONETIZE_ENABLED,
)

export const PUSHER_KEY = import.meta.env.VITE_PUSHER_KEY
export const PUSHER_CLUSTER = import.meta.env.VITE_PUSHER_CLUSTER
