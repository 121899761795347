import { pipe } from "fp-ts/function"
import * as O from "fp-ts/Option"
import { User } from "oidc-client-ts"

import { mapToOidcUser } from "./api/api-utils"
import { UserId, UserName } from "./api/branded-types"
import { AUTHORITY, CLIENT_ID } from "./envs"

export class LocalStorage {
    private constructor() {}

    static clear = () => {
        localStorage.clear()
        sessionStorage.clear()
    }

    static setAccessToken = (newAccessToken: string): void =>
        pipe(
            `oidc.user:${AUTHORITY}:${CLIENT_ID}`,
            key => O.tryCatch(() => localStorage.getItem(key)),
            O.chain(O.fromNullable),
            O.map(userString => {
                const user = JSON.parse(userString)
                user.accessToken = newAccessToken
                return user
            }),
            O.map(updatedUser => {
                localStorage.setItem(
                    `oidc.user:${AUTHORITY}:${CLIENT_ID}`,
                    JSON.stringify(updatedUser),
                )
            }),
            O.fold(
                () =>
                    console.error(
                        "Failed to set access token since OIDC user not found",
                    ),
                () => {},
            ),
        )

    static getAccessToken = () =>
        pipe(
            this.getOidcUser(),
            O.fold(
                () => undefined,
                ({ accessToken }) => accessToken,
            ),
        )

    static getRefreshToken = () =>
        pipe(
            this.getOidcUser(),
            O.fold(
                () => undefined,
                ({ refreshToken }) => refreshToken,
            ),
        )

    static getAccessTokenExpiresAt = () =>
        pipe(
            this.getOidcUser(),
            O.fold(
                () => undefined,
                ({ expiresAt }) => expiresAt,
            ),
        )

    static getIdToken = () =>
        pipe(
            this.getOidcUser(),
            O.fold(
                () => undefined,
                ({ idToken }) => idToken,
            ),
        )

    static getAccountType = () =>
        pipe(
            this.getOidcUser(),
            O.map(({ accountType }) => accountType),
        )

    static getAccountTypeOrDefault = () =>
        pipe(
            LocalStorage.getAccountType(),
            O.getOrElseW(() => "Guest" as const),
        )

    static getOidcUserId = () =>
        pipe(
            `oidc.user:${AUTHORITY}:${CLIENT_ID}`,
            key => O.tryCatch(() => localStorage.getItem(key)),
            O.chain(O.fromNullable),
            O.map(User.fromStorageString),
            O.fold(
                () => undefined,
                ({ profile }) => profile.sub,
            ),
            O.fromNullable,
            O.map(UserId.parse),
        )

    static getOidcUser = () =>
        pipe(
            `oidc.user:${AUTHORITY}:${CLIENT_ID}`,
            key => O.tryCatch(() => localStorage.getItem(key)),
            O.chain(O.fromNullable),
            O.map(User.fromStorageString),
            O.map(mapToOidcUser),
        )

    static getUsername = () =>
        pipe(
            O.tryCatch(() => localStorage.getItem("username")),
            O.chain(O.fromNullable),
            O.chain(value => {
                const result = UserName.safeParse(value)
                return result.success ? O.some(result.data) : O.none
            }),
        )

    static getUsernameOrUndefined = () =>
        pipe(
            this.getUsername(),
            O.fold(
                () => undefined,
                name => name,
            ),
        )
}
